<template>
    <list-content
      v-bind:rows-data="tableData"
      v-bind:columns="tableColumns"
      v-bind:add-label="pageContent.add_label"
      v-bind:title="pageContent.title"
      v-bind:new-uri="pageContent.newUri"
      v-bind:edit-uri="pageContent.editUri"
      v-bind:show-message="showMessage"
      v-bind:message="message"
      @handlePageChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleSearchData="performSearch"
      @handleDeleteData="handleDelete"

    ></list-content>
</template>

<script>
import MenuService from "@/api/menu.service";
import ListContent from "@/components/MenusContent";
import Draggable from "vue3-draggable";

export default {
  name: "Menus",
  components: {
     Draggable,
    ListContent,
  },
  data() {
    return {
      tableColumns: [
          {'key':'name', 'label':' Menu Name', 'sortable':false, 'is_image': false, 'width':''},
          {'key':'sort_order', 'label':'Sort Order', 'sortable':true, 'is_image': false, 'width':152},
          {'key':'status', 'label':'Status', 'sortable':true, 'is_image': false, 'width':102},
      ],
      pageContent: {
        'add_label': 'Add Menu',
        'title': 'Navigation Menu',
        'newUri': '/menu/add',        
        'editUri':'/menu/edit'
      },
      tableData: [],
      page: 1,
      search: '',
      //meta: Object,
      showMessage: false,
      message: {message:'', messageType:'success', effect:'dark'}
    }
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      return MenuService.Menu(this.page, this.search).then(response => {
         //this.meta = response.data.meta;
         this.tableData = response.data.data;
      });
    },
    performSearch(search) {
      this.search = search.name;
      this.getTableData();
    },
    handleDelete(data) {
      let deleteRow = data.row;
      this.showMessage = false;
      if (data.indexNo >= 0) {
        return MenuService.delete(deleteRow).then(response => {
          if(response.status === 201) {
            this.message.message = "Menu deleted successfully";
            this.message.messageType = "success";
            this.message.effect = "dark";
            this.showMessage = true;
            this.getTableData();
          }
        });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTableData();
    },
    handleSizeChange(val){
      this.getTableData();
    }
  }
}
</script>
 <style lang="scss">
  .el-input--mini .el-input__inner {
    height: 40px;
}
</style>
<style lang="scss" scoped>
.topsearch .el-input {
    width: 500px;
    }
.heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
  .el-table::v-deep(.tableHeading) {
    background: #bebbc936 !important;
    text-transform: uppercase;
    font-size :.657rem;
    letter-spacing :.5px;
    color:#6e6b7b;
    font-family: 'Montserrat,Helvetica,Arial,serif';    
  }
.el-table .success-row {
    background: #f0f9eb61;
  }
 div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
.table-row-heading{background: #bebbc936 !important;
    text-transform: uppercase;
    font-size: 0.857rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #6e6b7b;
    font-family: Montserrat, Helvetica, Arial, serif;
    line-height: 1.45;
    height: 40px;
    padding: 0;
    padding: 10px;
    }
  .table-col-data{box-sizing: border-box;padding: 12px0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: left;}
</style>
