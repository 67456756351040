<template>
  <el-row :gutter="20">
    <el-col
        :xs="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
    >
      <div class="heading">
        <h1>{{title}}</h1>
      </div>
    </el-col>
    <el-row :gutter="20">
      <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
      >
         <div v-if="showMessage">
          <el-alert
              title="Menu deleted successfully"
              type="success"
              effect="dark"
          />
          <!--el-alert
              title="{{message.message}} "
              type="{{message.messageType}} "
              effect="{{message.effect}} "
          /-->
        </div>
      </el-col>
    </el-row>
    <el-col
        :xs="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
    >
      <div class="topsearch">
        <el-input
            v-model="search"
            size="small"
            placeholder="Type to search by name"
            @keyup="performSearch"
        >
          <template #append>
            <el-button icon="search" @click="doClickSearch()"></el-button>
          </template>
        </el-input>
        <el-button
            type="primary"
            @click="performAddNew()"
        >
          {{addLabel}}
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-skeleton style="width: 100%;height: 300px;" :loading="loading" animated :count="3">
    <template #template>
      <div style="width: 100%;margin-top: 40px;">
        <el-skeleton-item variant="text" style="width:2%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:60%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:10%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:5%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:5%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:5%;margin-left: 20px;" />
      </div>
    </template>
    <template #default>
      <menu-editor
          v-bind:menu-options="rowsData" v-if="rowsData.length" @performEdit="performEdit" @performAddNew="performAddNew" @performDelete="performDelete" @performSaveChanges="performSaveChanges">
      </menu-editor>
    </template>
  </el-skeleton>
</template>

<script>
import settings from "@/settings";
import MenuEditor from '@/views/MenuEditor'
import MenuService from "@/api/menu.service";

export default {
  name: "list-content",
  components: {
    MenuEditor
  },
  props: {
    columns: Array,
    rowsData: Array,
    type: {
      type: String, // striped | hover
      default: "striped"
    },
    title: {
      type: String,
      default: "List"
    },
    subTitle: {
      type: String,
      default: ""
    },
    addLabel: {
      type: String,
      default: 'Add New'
    },
    message: {message:String, messageType: String, effect: String},
    showMessage: {
      type: Boolean,
      default: false
    },
    newUri: String,
    editUri: String
  },
  data() {
    return {
      page: 1,
      pageSize: settings.pagination.size,
      pageSizes: [1,2,3,4,5,10,25,50,100],
      multipleSelection: [],
      totalPages: 0,
      search: '',
      loading: true,
    }
  },
  mounted() {

  },
  computed: {
  },
  watch: {
    rowsData: function () {
      // this.totalPages = this.meta.total;
      this.loading = false
    }
  },
  emits: ["handlePageChange", "handleSearchData", "handleDeleteData", "handleSizeChange"],
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (row && (rowIndex % 2 === 1)) {
        return 'table-row success-row';
      }else {
        return 'table-row';
      }
    },
    tableCellClassName() {
      return 'tableHeading';
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.$emit("handlePageChange", val);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      settings.pagination.size = val;
      this.$emit("handleSizeChange", val);
    },
    doClickSearch() {
      if(this.search === '') {
        return;
      }
      this.$emit("handleSearchData", {'name':this.search});
    },
    performSearch(event) {
      if (event.key === "Enter" || (event.key === "Backspace" && this.search === '')) {
        this.$emit("handleSearchData", {'name':this.search});
      }
    },
    performAddNew() {
      this.$router.push(this.newUri);
    },
    performEdit(item){
      this.$router.push(this.editUri+"/"+item.id);
    },
    performDelete(item, deleteRow = null) {
      let result = confirm("Are you sure to delete this?");
      if (item.id >= 0 && result) {
        this.$emit("handleDeleteData", {'indexNo':item.id, 'row': item});
      }
    },


    performSaveChanges(menuElements) {
      let sortedMenu = []
      menuElements.map(function (m){
        let menu = {id: m.id, children: []}
        m.children.map(function (me){
          menu.children.push({id: me.id})
        })
        sortedMenu.push(menu)
      })
      this.saveNestedSort(sortedMenu)
    },
    saveNestedSort(sortedMenu) {
      return MenuService.saveNestedSort({menu: sortedMenu}).then(response => {
        this.showAlertAdded=true;
        this.$emit("handleSearchData", {name: null});
      });
    }
  }
}
</script>

<style lang="scss">
.el-input--mini .el-input__inner {
  height: 40px;
}
</style>

<style lang="scss" scoped>
.topsearch .el-input {
  width: 500px;
}

.heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
div .topsearch {
  float: right;
  display: inline-flex;
  padding: 15px 0px;
}

div .topsearch .el-input__inner {
  padding-right: 10px;
  height: 40px;
  width: 500px;
}

div .topsearch .el-input {
  padding-right: 10px;
}
.pagination {
  padding: 12px 5px;
  float: right;
}
.el-table :deep(.tableHeading) {
  background: #bebbc936 !important;
  text-transform: uppercase;
  font-size: .857rem;
  font-weight: 600;
  letter-spacing: .5px;
  color: #6e6b7b;
  font-family: Montserrat,Helvetica,Arial,serif;
  line-height: 1.45;
  height: 40px;
  padding: 0;
}
.el-table__row {
  height: 50px;
}
.el-table ::v-deep(.success-row) {
  //background: #f0f9eb61;
}
.el-pagination :deep(button) {
  border-radius: 50% !important;
}
.el-pagination :deep(.el-pager .number) {
  border-radius: 50% !important;
}
.el-pagination :deep(.el-pager .active) {
  background-color: #f00 !important;
}
.el-table :deep(.table-row td) {
  padding: 5px !important;
}
</style>